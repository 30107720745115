import React, { Component } from 'react';
import moment from 'moment';

const lastUsedColor = ['green','orange','red','red','red','red','red','red','red','red'];

class Skills extends Component {
  render() {
    if(this.props.data){
      var skills = this.props.data.skills.map(function(main, index){
        return <div className="two columns" key={index}>
        <h4>{main.title}</h4>
         {
          main.items.map(function(skill){
            const lastUsed = moment(skill.lastUsed,'DD-MM-YYYY');
            const years = moment().diff(lastUsed, 'years');
          return <li key={skill.name}>

            <span style={{width:skill.level}} className="bar-expand">  {/*background:skill.color*/}
              <span className="skill_name"> {skill.name} </span>
               </span>
           
          </li>
          })
        }
         </div>
      })
    }

    return (
      <section id="skill" >


      <div className="row skill" style={{maxWidth:'none'}}>
         <div className="twelve columns">
  				<div className="bars">
  					  { skills }
  				</div>
			   </div>
          </div>
      





   </section>
    );
  }
}

export default Skills;
